import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};
/**
 * Canvas
 */
const CANVAS = document.querySelector('.webgl');

/**
 * Scene
 */
const scene = new THREE.Scene();

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.z = 15;
camera.position.x = 6;
camera.position.y = 4;

/**
 * TextureLoader
 */
const textureLoader = new THREE.TextureLoader();
const matCap = textureLoader.load('/textures/8.png');

/**
 * Geometry
 */
const coneMaterial = new THREE.MeshMatcapMaterial();

coneMaterial.matcap = matCap;

const sphereGeometry = new THREE.SphereGeometry(1);

for (let i = 0; i < 750; i++) {
  const sphereMesh = new THREE.Mesh(sphereGeometry, coneMaterial);
  sphereMesh.position.x = (Math.random() - 0.5) * 100;
  sphereMesh.position.y = (Math.random() - 0.5) * 100;
  sphereMesh.position.z = (Math.random() - 0.5) * 100;

  scene.add(sphereMesh);
}

/**
 * Fonts
 */
const fontLoader = new FontLoader();
fontLoader.load('fonts/helvetiker_regular.typeface.json', (font) => {
  const textGeometry = new TextGeometry('Mirko Agresti\nWeb developer', {
    font: font,
    size: 3,
    height: 0.2,
    curveSegments: 5,
    bevelEnabled: true,
    bevelThickness: 1,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 4,
  });

  textGeometry.center(); // centra le textGeometry
  const material = new THREE.MeshMatcapMaterial({ matcap: matCap });
  const text = new THREE.Mesh(textGeometry, material);
  scene.add(text);
  camera.lookAt(text.position);
});

/**
 * Clock
 */
const clock = new THREE.Clock();

/**
 * Controls
 */
const controls = new OrbitControls(camera, CANVAS);

controls.enableDamping = true;

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Renderer
 */
const renderer = new THREE.WebGL1Renderer({ canvas: CANVAS });
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const animate = () => {
  // const elapsedTime = clock.getElapsedTime();

  // centerGroup.position.y = Math.sin(elapsedTime);
  // centerGroup.position.x = Math.cos(elapsedTime);

  renderer.render(scene, camera);
  controls.update();

  window.requestAnimationFrame(animate);
};

animate();
